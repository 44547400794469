import { useEffect } from "react";
import { Container, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";

import LeftSection from "./Left.section";
import RightSection from "./Right.section";
import schemas from "./schema";

const hostname = window?.location?.hostname || "";
// While development uncomment following line and comment next line.
// const schema = schemas["resume.dashmeetart.com"];
// const schema = schemas["resume.dilpreetkaur.com"];
const schema = schemas[hostname];

export default function Resume() {
  const [isPrint] = useMediaQuery("print");

  useEffect(() => {
    if (schema?.basics?.name) {
      document.title = [schema.basics.name, "Resume"].join(" | ");
    }
  }, []);

  if (!schema) {
    console.error(
      `There is no relevant schema present for hostname - "${hostname}"`
    );
    return null;
  }
  return (
    <main>
      <Container my={isPrint ? 0 : 8} maxW={isPrint ? "100%" : "container.md"}>
        <Grid
          templateColumns={isPrint ? "2fr 1fr" : [null, null, "2fr 1fr"]}
          gap="8"
        >
          <GridItem>
            <LeftSection schema={schema} />
          </GridItem>
          <GridItem>
            <RightSection schema={schema} />
          </GridItem>
        </Grid>
      </Container>
    </main>
  );
}
