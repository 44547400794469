import Moment from "react-moment";
import {
  useMediaQuery,
  Box,
  Badge,
  Heading,
  Text,
  HStack,
  Image,
  UnorderedList,
  ListItem,
  Flex
} from "@chakra-ui/react";
import { VStack, SectionHeading } from "./Components";

export default function LeftSection({ schema }) {
  const [isPrint] = useMediaQuery("print");

  return (
    <VStack spacing="8">
      <VStack width="full">
        <HStack spacing="4">
          {Boolean(schema.basics.picture) && (
            <Image
              borderRadius="full"
              boxSize="4em"
              src={schema.basics.picture}
              objectFit="cover"
              objectPosition="left"
              filter="grayscale(100%)"
              alt={schema.basics.name}
            />
          )}
          <Box>
            <Heading>{schema.basics.name}</Heading>
            <Text>{schema.basics.label}</Text>
          </Box>
        </HStack>
        <Flex
          borderY="1px"
          borderColor="gray.300"
          width="full"
          justifyContent="space-between"
          flexWrap="wrap"
          columnGap="6"
          rowGap="2"
          py="1"
        >
          <Text>{schema.basics.email}</Text>
          <Text>{schema.basics.phone}</Text>
        </Flex>
      </VStack>
      {Boolean(schema.basics.summary) && (
        <VStack spacing="2">
          <SectionHeading>SUMMARY</SectionHeading>
          <Text>{schema.basics.summary}</Text>
        </VStack>
      )}
      {schema.education && (
        <VStack spacing="2">
          <SectionHeading>EDUCATION</SectionHeading>
          <VStack>
            {schema.education.map((education) => (
              <HStack
                align="flex-start"
                spacing="4"
                key={[education.title, education.year].join("-")}
              >
                <Text
                  minW="3.5em"
                  borderRight="2px"
                  borderRightColor="gray.300"
                >
                  <Moment date={education.endDate} format="YYYY" />
                </Text>
                <VStack spacing="1">
                  <Text>
                    <strong>
                      {[
                        education.studyType,
                        Boolean(education.area) && `(${education.area})`
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </strong>
                    {Boolean(education.gpa) && (
                      <>
                        {" "}
                        <Badge colorScheme="orange">{education.gpa}</Badge>
                      </>
                    )}
                  </Text>
                  <Text>{education.institution}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </VStack>
      )}
      {schema.work && (
        <VStack spacing="2">
          <SectionHeading>EXPERIENCE</SectionHeading>
          <VStack>
            {schema.work.map((work, index) => (
              <VStack
                width="full"
                spacing="1"
                key={[work.company, work.position, work.startDate, index].join(
                  "-"
                )}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="baseline"
                  width="full"
                  gap={isPrint ? 4 : [null, null, 4]}
                  direction={isPrint ? "row" : ["column-reverse", null, "row"]}
                >
                  <Text>
                    <strong>
                      {work.position}, {work.company}
                    </strong>
                  </Text>
                  <Badge>
                    <Moment date={work.startDate} format="MMM, YYYY" />
                    {" - "}
                    {(Boolean(work.endDate) && (
                      <Moment date={work.endDate} format="MMM, YYYY" />
                    )) ||
                      "Present"}
                  </Badge>
                </Flex>
                {Boolean(work.highlights.filter(Boolean).length) && (
                  <Box>
                    <UnorderedList>
                      {work.highlights
                        .filter(Boolean)
                        .map((highlight, index) => (
                          <ListItem key={[highlight, index].join("-")}>
                            {highlight}
                          </ListItem>
                        ))}
                    </UnorderedList>
                  </Box>
                )}
              </VStack>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
}
