import { Wrap, Badge, Text, Link, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Moment from "react-moment";
import { VStack, SectionHeading } from "./Components";

export default function RightSection({ schema }) {
  const [isPrint] = useMediaQuery("print");

  return (
    <VStack spacing="8">
      {Boolean(schema.awards) && (
        <VStack spacing="2">
          <SectionHeading>AWARDS</SectionHeading>
          {schema.awards.map((award, index) => (
            <VStack key={award.title || index} spacing="1">
              <Text>
                <Badge>
                  <Moment date={award.date} format="MMM, YYYY" />
                </Badge>
              </Text>
              <Text>
                <strong>{award.title}</strong>
              </Text>
              <Text>{award.awarder}</Text>
            </VStack>
          ))}
        </VStack>
      )}
      {Boolean(schema.skills) && (
        <VStack spacing="2">
          <SectionHeading>SKILLS</SectionHeading>
          {schema.skills.map((skill, index) => (
            <VStack key={skill.name || index} spacing="2">
              {Boolean(skill.name) && (
                <Text>
                  <strong>{skill.name}</strong>
                </Text>
              )}
              {Boolean(skill.keywords) && (
                <Wrap direction="row" flexWrap="wrap">
                  {skill.keywords.map((keyword) => (
                    <Badge key={keyword} colorScheme="red">
                      {keyword}
                    </Badge>
                  ))}
                </Wrap>
              )}
            </VStack>
          ))}
        </VStack>
      )}
      {Boolean(schema.interests) && (
        <VStack spacing="2">
          <SectionHeading>INTERESTS</SectionHeading>
          {schema.interests.map((interest, index) => (
            <VStack key={interest.name || index} spacing="2">
              {Boolean(interest.name) && (
                <Text>
                  <strong>{interest.name}</strong>
                </Text>
              )}
              {Boolean(interest.keywords) && (
                <Wrap direction="row" flexWrap="wrap">
                  {interest.keywords.map((keyword) => (
                    <Badge key={keyword} colorScheme="red">
                      {keyword}
                    </Badge>
                  ))}
                </Wrap>
              )}
            </VStack>
          ))}
        </VStack>
      )}
      {Boolean(schema.languages) && (
        <VStack spacing="2">
          <SectionHeading>LANGUAGES</SectionHeading>
          <Wrap direction="row" flexWrap="wrap">
            {schema.languages.map((language, index) => (
              <Badge key={language.language || index} colorScheme="red">
                {language.language}
              </Badge>
            ))}
          </Wrap>
        </VStack>
      )}
      {Boolean(schema.basics.profiles) && (
        <VStack spacing="2">
          <SectionHeading>PROFILES</SectionHeading>
          <Wrap direction="row" flexWrap="wrap">
            {schema.basics.profiles.map((profile, index) => (
              <React.Fragment key={profile.network || index}>
                {isPrint ? (
                  <>
                    <Link href={profile.url}>
                      <Badge>{new URL(profile.url).hostname}</Badge>
                      {new URL(profile.url).pathname}
                    </Link>
                  </>
                ) : (
                  <Badge colorScheme="red">
                    <Link href={profile.url} isExternal>
                      {profile.network}
                    </Link>
                  </Badge>
                )}
              </React.Fragment>
            ))}
          </Wrap>
        </VStack>
      )}
    </VStack>
  );
}
